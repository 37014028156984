<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <validation-observer ref="scopeRules">
      <b-form @submit.prevent>
        <b-row class="invoice-preview">
          <b-col cols="12">
            <b-card>
              <b-row>
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Scope Details</th>
                    </tr>
                  </thead>
                </table>
              </b-row>

              <b-row class="mt-1">
                <b-col cols="4">
                  <b-form-group label="Client*" label-for="h-client">
                    <validation-provider name="Client" #default="{ errors }" rules="required">
                      <v-select v-model="scopeData.client" :taggable="true" :loading="dropdownLoading" @option:created="createNewContact" :options="clients" :reduce="(val) => val.id" label="value" @input="emailUpdate"> </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <b-form-group label="Email" label-for="d-email">
                    <validation-provider name="Email" #default="{ errors }" rules="email">
                      <b-form-input v-model="scopeData.email" id="d-email" placeholder="Email" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <b-form-group label="Project Name*" label-for="h-projectName">
                    <validation-provider name="Project Name" #default="{ errors }" rules="required">
                      <b-form-input v-uppercase v-model="scopeData.projectName" id="h-surname" placeholder="Project Name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-group label="Total Price" label-for="h-price">
                    <cleave id="popover-input-2" ref="input2" required v-model="scopeData.price" class="form-control" style="height: 2.742rem;" :raw="true" :options="options.price" />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-group label="Currency" label-for="h-client">
                    <v-select v-model="scopeData.currency" :taggable="true" :loading="dropdownLoading" :options="currencies" :reduce="(val) => val.id" label="value"> </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Introduction*" label-for="h-introduction">
                    <validation-provider name="Introduction" #default="{ errors }" rules="required">
                      <b-form-textarea v-uppercase v-model="scopeData.introduction" id="introduction" placeholder="Introduction" rows="3" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group label="Description*" label-for="h-description">
                    <validation-provider name="description" #default="{ errors }" rules="required">
                      <b-form-textarea v-uppercase v-model="scopeData.description" id="description" placeholder="Description" rows="3" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <fieldset>
                <h6>Scope Tasks</h6>
                <!-- name -->

                <template>
                  <div class="task-list-container mb-5">
                    <b-row>
                      <b-col cols="11">
                        <b-form-group label-for="h-taskName">
                          <b-form-input v-uppercase v-model="tempTask" id="h-taskName" placeholder="Task Name" />
                        </b-form-group>
                      </b-col>

                      <b-col cols="1">
                        <b-form-group>
                          <b-button variant="primary" @click="addTask"><feather-icon icon="PlusIcon"/></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <task-list :tasks="tasks" class="task-list p-2"></task-list>
                    </b-row>
                  </div>
                </template>
                <!-- surname -->
              </fieldset>

              <!-- Buttons -->
              <b-row>
                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button @click.prevent="formSubmitted" type="submit" variant="primary" class="mr-1 float-right">
                    Save
                  </b-button>
                </b-col>
              </b-row>
              <!-- Buttons Finish -->
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import scopeStoreModule from './scopeStoreModule';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import { required, email } from '@validations';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import Cleave from 'vue-cleave-component';
import TaskList from './TaskList.vue';

export default {
  components: {
    Cleave,
    vSelect,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    router,
    store,
    scopeStoreModule,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    AppTimeline,
    AppTimelineItem,
    TaskList,
  },
  directives: {
    Ripple,
    uppercase: {
      update(element) {
        element.value = element.value.toUpperCase();
      },
    },
  },

  setup() {
    const LEAD_APP_STORE_MODULE_NAME = 'scope';
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.registerModule(LEAD_APP_STORE_MODULE_NAME, scopeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME)) store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    var scopeDataModel = {
      client: null,
      email: null,
      projectName: null,
      introduction: null,
      description: null,
      price: null,
      tasks: [],
      currency: 'GBP',
    };

    var localUser = JSON.parse(localStorage.getItem('userData'));

    return {
      tempTask: null,
      dropdownLoading: true,
      logged: false,
      localUser,
      responseDate: null,
      responseMethod: null,
      note: null,
      interactionLogs: {
        leadId: null,
        responseMethod: null,
        responseDate: null,
        note: null,
      },
      clients: [],
      loading: true,
      scopeData: scopeDataModel,
      id: null,
      required,
      email,
      currencies: ['GBP', 'USD', 'EUR'],

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
        price: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: '.',
          delimiter: ',',
          numeralIntegerScale: 9,
        },
      },

      tasks: [],
    };
  },

  methods: {
    emailUpdate(val) {
      if (val != null) {
        this.scopeData.email = this.clients.find((e) => e.id == val).email;
      } else {
        this.scopeData.email = null;
      }
    },

    addTask() {
      if (this.tempTask != null && this.tempTask != '') {
        const newTask = {
          id: Date.now().toString(),
          name: this.tempTask,
          subtasks: [],
        };
        this.tempTask = null;
        this.tasks.push(newTask);
      }
    },

    createNewContact(val) {
      this.dropdownLoading = true;

      store.dispatch('scope/newContact', { name: val.value }).then((response) => {
        this.clients.unshift({
          id: Number(response.data.id),
          value: String(response.data.firstName ?? null + ' ' + response.data.lastName ?? null),
          email: response.data.email,
        });

        this.scopeData.client = response.data.id;
        this.dropdownLoading = false;
      });
    },

    formSubmitted() {
      this.$refs.scopeRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.scopeData.tasks = this.tasks;

          if (this.id != null) {
            if (this.scopeData.status == 'A') {
              this.scopeData.status == 'B';
            }

            store.dispatch('lead/updateScope', this.scopeData).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Scope has been updated',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getByIdLead();
            });
          } else {
            this.scopeData.status = 'B';
            store.dispatch('scope/newScope', this.scopeData).then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ The Scope has been created.',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });

              this.loading = false;

              // router.push({
              //   name: "leads-preview",
              //   params: { id: response.data.id },
              // });
            });
          }
        }
      });
    },

    getByIdLead() {
      if (router.currentRoute.params.id) {
        this.id = router.currentRoute.params.id;
        store
          .dispatch('lead/getLeadById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.scopeData = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'leads-list' });
          });
      } else {
        this.loading = false;
      }
    },

    getClients() {
      store
        .dispatch('scope/fetchClients', [])
        .then((res) => {
          res.data.clients.forEach((element) => {
            if (element != null) {
              this.clients.push({
                id: Number(element.id),
                value: String(element.firstName ?? null + ' ' + element.lastName ?? null),
                email: element.email,
              });
            }
          });
          this.dropdownLoading = false;

          this.getByIdLead();
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Client list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    varicon(val) {
      if (val == 'SMS') {
        return 'notesSquareIcon';
      } else if (val == 'PHONE') {
        return 'PhoneCallIcon';
      } else if (val == 'EMAIL') {
        return 'MailIcon';
      } else if (val == 'LETTER') {
        return 'FileTextIcon';
      } else {
        return 'InboxIcon';
      }
    },

    varyant(val) {
      if (val == 'A') {
        return 'secondary';
      } else if (val == 'B') {
        return 'warning';
      } else if (val == 'C') {
        return 'primary';
      } else if (val == 'D') {
        return 'success';
      } else if (val == 'E') {
        return 'info';
      }
    },

    toastnotes(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.scopeRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },

  formatPrice(value, val) {
    if (value != null) {
      let val = (value / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },

  created() {
    this.getClients();
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
