<template>
  <div>
    <scope-add></scope-add>
  </div>
</template>

<script>
import ScopeAdd from './ScopeAdd.vue';

export default {
  components: {
    ScopeAdd,
  },

  data() {
    return {};
  },
};
</script>
