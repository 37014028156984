var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","no-fade":""}},[_c('validation-observer',{ref:"scopeRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"invoice-preview"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Scope Details")])])])])]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Client*","label-for":"h-client"}},[_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"taggable":true,"loading":_vm.dropdownLoading,"options":_vm.clients,"reduce":function (val) { return val.id; },"label":"value"},on:{"option:created":_vm.createNewContact,"input":_vm.emailUpdate},model:{value:(_vm.scopeData.client),callback:function ($$v) {_vm.$set(_vm.scopeData, "client", $$v)},expression:"scopeData.client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"d-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"d-email","placeholder":"Email"},model:{value:(_vm.scopeData.email),callback:function ($$v) {_vm.$set(_vm.scopeData, "email", $$v)},expression:"scopeData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Project Name*","label-for":"h-projectName"}},[_c('validation-provider',{attrs:{"name":"Project Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"h-surname","placeholder":"Project Name"},model:{value:(_vm.scopeData.projectName),callback:function ($$v) {_vm.$set(_vm.scopeData, "projectName", $$v)},expression:"scopeData.projectName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Total Price","label-for":"h-price"}},[_c('cleave',{ref:"input2",staticClass:"form-control",staticStyle:{"height":"2.742rem"},attrs:{"id":"popover-input-2","required":"","raw":true,"options":_vm.options.price},model:{value:(_vm.scopeData.price),callback:function ($$v) {_vm.$set(_vm.scopeData, "price", $$v)},expression:"scopeData.price"}})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Currency","label-for":"h-client"}},[_c('v-select',{attrs:{"taggable":true,"loading":_vm.dropdownLoading,"options":_vm.currencies,"reduce":function (val) { return val.id; },"label":"value"},model:{value:(_vm.scopeData.currency),callback:function ($$v) {_vm.$set(_vm.scopeData, "currency", $$v)},expression:"scopeData.currency"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Introduction*","label-for":"h-introduction"}},[_c('validation-provider',{attrs:{"name":"Introduction","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"introduction","placeholder":"Introduction","rows":"3"},model:{value:(_vm.scopeData.introduction),callback:function ($$v) {_vm.$set(_vm.scopeData, "introduction", $$v)},expression:"scopeData.introduction"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Description*","label-for":"h-description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"description","placeholder":"Description","rows":"3"},model:{value:(_vm.scopeData.description),callback:function ($$v) {_vm.$set(_vm.scopeData, "description", $$v)},expression:"scopeData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('fieldset',[_c('h6',[_vm._v("Scope Tasks")]),[_c('div',{staticClass:"task-list-container mb-5"},[_c('b-row',[_c('b-col',{attrs:{"cols":"11"}},[_c('b-form-group',{attrs:{"label-for":"h-taskName"}},[_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"h-taskName","placeholder":"Task Name"},model:{value:(_vm.tempTask),callback:function ($$v) {_vm.tempTask=$$v},expression:"tempTask"}})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addTask}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1),_c('b-row',[_c('task-list',{staticClass:"task-list p-2",attrs:{"tasks":_vm.tasks}})],1)],1)]],2),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }