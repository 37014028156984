<template>
  <div class="task-list">
    <div class="grid">
      <div v-for="task in tasks" :key="task.id" class="task-item">
        <div class="task-header">
          <div>{{ task.name }}</div>
          <b-button variant="danger" size="sm" class="task-remove" @click="removeTask(task.id)">
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <hr />
        <ul class="subtask-list mt-2 pr-5 pl-5">
          <li v-for="subtask in task.subtasks" :key="subtask.id" class="subtask-item mt-1" style="  border-bottom: dotted 1px rgb(175, 175, 175); ">
            {{ subtask.name }}
            <b-button variant="danger" size="sm" @click="removeSubtask(task.id, subtask.id)">
              <feather-icon icon="XIcon" />
            </b-button>
          </li>
          <li class="subtask-input-container">
            <b-form-input v-model="subtaskInputValue[task.id]" id="h-subtaskName" placeholder="Add Subtask" />
            <b-button variant="primary" size="sm" @click="addSubtask(task.id)">
              <feather-icon icon="PlusIcon" />
            </b-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'TaskList',
  props: {
    tasks: Array,
  },

  components: {
    BButton,
  },

  data() {
    return {
      subtaskInputValue: {}, // object to store input values for each task
    };
  },

  methods: {
    addSubtask(taskId) {
      var taskInputRef = this.subtaskInputValue[taskId];

      const index = this.tasks.findIndex((task) => task.id === taskId);

      const newSubtask = {
        id: Date.now().toString(),
        name: taskInputRef,
      };

      if (index !== -1) {
        this.tasks[index].subtasks.push(newSubtask);
      }

      this.subtaskInputValue[taskId] = null;
    },

    removeTask(taskId) {
      const index = this.tasks.findIndex((t) => t.id === taskId);
      this.tasks.splice(index, 1);
    },

    removeSubtask(taskId, subtaskId) {
      const task = this.tasks.find((t) => t.id === taskId);
      const index = task.subtasks.findIndex((s) => s.id === subtaskId);
      task.subtasks.splice(index, 1);
    },
  },
};
</script>

<style>
.task-list {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;

  /* grid-template-columns: repeat(auto-fill, minmax(40%, 1fr)); */
  grid-gap: 20px;
}

.task-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtask-list {
  margin-top: 10px;
  padding-left: 20px;
}

.subtask-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.subtask-input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

#h-taskName,
#h-subtaskName {
  margin-right: 10px;
}

.task-remove {
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
</style>
